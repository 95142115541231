import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const OmOss = ({ data }) => {
  return (
    <Layout>
      <Seo title="Om oss" />

      <section className="about-main">
        <div className="container-fluid">
          <h1>Om oss</h1>
          {data?.about?.acfAbout?.content?.map((item, index) => (
            <div className="row custom_row" key={index}>
              <div className="col-lg-6">
                <div className="custom_row--inner">
                  <h3>{item.header}</h3>
                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              </div>
              <div className="col-lg-6 no-p">
                <img
                  className="img-fluid"
                  src={item?.image?.localFile?.publicURL}
                  alt=""
                />
              </div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    about: wpPage(id: { eq: "cG9zdDo3MA==" }) {
      acfAbout {
        content {
          image {
            localFile {
              publicURL
            }
          }
          header
          description
        }
      }
    }
  }
`

export default OmOss
